import { Grid, Skeleton } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { useStyles } from './TileSkeleton.styles';

export function TileLoadingContent() {
    const { classes } = useStyles();
    return (
        <>
            {Array(16)
                .fill(true)
                .map(() => (
                    <Grid key={uuidv4()} className={classes.projectTile}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            className={classes.projectTilePending}
                        />
                    </Grid>
                ))}
        </>
    );
}
